import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpClientHeaders {

    constructor(private http: HttpClient) {
    }

    createAuthorizationHeader(): HttpHeaders {
        return new HttpHeaders().set('authorization', 'Bearer ');
    }

    get<T>(url: string, options?: {
        headers?: HttpHeaders | {
            [ header: string ]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [ param: string ]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<T> {
        if (!options) {
            options = {};
        }
        this.createAuthorizationHeader();
        options.headers = this.createAuthorizationHeader();
        return this.http.get<T>(url, options);
    }

    post<T>(url: string, data: any | null, options?: {
        headers?: HttpHeaders | {
            [ header: string ]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [ param: string ]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<T> {
        if (!options) {
            options = {};
        }
        options.headers = this.createAuthorizationHeader();
        return this.http.post<T>(url, data, options);
    }

    put<T>(url: string, data: any | null, options?: {
        headers?: HttpHeaders | {
            [ header: string ]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [ param: string ]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<T> {
        if (!options) {
            options = {};
        }
        options.headers = this.createAuthorizationHeader();
        return this.http.put<T>(url, data, options);
    }
}
