import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LoginService } from './login.service';
import { StorageService } from './storage.service';
import { ToastService } from '../../shared/services/toast.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    urlsNotToBeUsed: Array<string>;

    constructor(private router: Router, private loginService: LoginService, private storageService: StorageService, private toastService: ToastService) {
        this.urlsNotToBeUsed = [
            environment.apiBaseUrl + '/login',
            environment.apiBaseUrl + '/logout',
            environment.apiBaseUrl + '/token'
        ];
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('intercept --->>>', request.url);
        return from(
            Promise.all([
                this.storageService.get('accessToken'),
                this.storageService.get('refreshToken')
            ])
        ).pipe(
            switchMap(res => {
                if (this.isValidRequestForInterceptor(request.url)) {
                    if (res[ 0 ]) {
                        request = request.clone({
                            setHeaders: {
                                Authorization: 'Bearer ' + res[ 0 ]
                            }
                        });
                    }
                    return next.handle(request).pipe(
                        map((event: HttpEvent<any>) => {
                            if (event instanceof HttpResponse) {
                                console.log('event--->>>', event);
                            }
                            if (event instanceof HttpResponse && event.status === 204) {
                                console.log('next.handle(request).pipe 204', event);
                            }
                            return event;
                        }),
                        catchError((err) => {
                            console.log('next.handle(request).pipe catchError', err.status);
                            if (err.status === 204) {
                                console.log('next.handle(request).pipe catchError 204', err);
                            }
                            if (err.status === 401) {
                                console.error('AuthInterceptor intercept', err, 'call refreshToken');
                                return this.loginService.refreshToken(res[ 1 ]).pipe(
                                    switchMap((response) => {
                                        return next.handle(request.clone({
                                            setHeaders: {
                                                Authorization: 'Bearer ' + response.access_token
                                            }
                                        }));
                                    }),
                                    catchError((error) => {
                                        this.toastService.showError(error.error.gieerror.message);
                                        return this.loginService.logout().pipe(
                                            catchError((e) => {
                                                return this.router.navigate(['/']);
                                            })
                                        );
                                    })
                                );
                            }
                            if (err.error?.gieerror?.message) {
                                this.toastService.showError(err.error.gieerror.message);
                            }
                            return throwError(err);
                        })
                    );
                }
                return next.handle(request);
            })
        );
    }

    private isValidRequestForInterceptor(requestUrl: string): boolean {
        for (const address of this.urlsNotToBeUsed) {
            if (new RegExp(address).test(requestUrl)) {
                return false;
            }
        }
        return true;
    }
}
