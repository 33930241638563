import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(public toastController: ToastController) {
    }

    public async showError(error: string) {
        if (!error) {
            error = 'Une erreur inconnue s\'est produite.';
        }
        const toast = await this.toastController.create({
            message: error,
            duration: 5000,
            buttons: [{
                text: 'OK',
                role: 'cancel',
            }
            ]
        });
        toast.color = 'danger';
        await toast.present();
    }

    public async showInfo(info: string) {
        const toast = await this.toastController.create({
            message: info,
            duration: 5000,
            buttons: [{
                text: 'OK',
                role: 'cancel',
            }
            ]
        });
        toast.color = 'primary';
        await toast.present();
    }
}
