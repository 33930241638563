import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'middleWhitespace'
})
export class MiddleWhitespacePipe implements PipeTransform {

    transform(value: string): string {
        return value.substring(0, value.length / 2) + ' ' + value.substring(value.length / 2, value.length);
    }

}
