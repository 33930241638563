import { NgModule } from '@angular/core';
import { MiddleWhitespacePipe } from './middle-whitespace.pipe';

@NgModule({
    declarations: [MiddleWhitespacePipe],
    exports: [
        MiddleWhitespacePipe
    ],
    imports: []
})
export class PipesModule {
  static forRoot() {
    return {
      ngModule: PipesModule,
      providers: [],
    };
  }
}
