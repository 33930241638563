import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Platform } from '@ionic/angular';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppBrowserGuard implements CanActivate {
    constructor(
        private readonly platform: Platform,
        private router: Router
    ) {}

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // if desktop or mobile browser
        if (
          (
            this.platform.is('mobileweb') || this.platform.is('desktop')
          ) &&
          (environment.env !== 'local' && environment.env !== 'dev')
        ) {
            this.router.navigate(['subscription']);
            return false;
        }
        return true;
    }
}
