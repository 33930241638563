import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    async set(key: string, value: any) {
        await Storage.set({ key, value: JSON.stringify(value) });
    }

    async remove(key: string) {
        await Storage.remove({ key });
    }

    async clear(): Promise<any> {
        return await Storage.clear();
    }

    async get(key: string): Promise<any> {
        const val = await Storage.get({ key });
        return JSON.parse(val.value);
    }
}
