import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, Subscription } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { User } from '../model/user';
import { SubscriptionState } from '../model/subscription-state.enum';
import * as moment from 'moment';

export enum RetrieveUserOrigin {
  LOGIN = 'login'
}

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {
  get currentUser$(): Observable<User> {
    this.retrieveUser();
    return this._userSubject.asObservable();
  }

  constructor(
    private readonly router: Router,
    private httpClient: HttpClient,
    private storageService: StorageService
  ) {
  }

  private readonly _userSubject = new ReplaySubject<User>(1);
  private _user: User;
  private _userSubscription: Subscription;
  private _retrieving = false;

  public retrieveUser(origin: string = ''): void {
    if (
      origin !== RetrieveUserOrigin.LOGIN &&
      (this._user || this._retrieving)
    ) {
      return;
    }

    this._retrieving = true;

    this.getCurrentUser()
      .then(
        (userObs) => {
          this._userSubscription = userObs
            .subscribe(
              (user) => {

                this._userSubscription.unsubscribe();
                this._userSubscription = undefined;

                this._user = user;
                this._user.name = user.firstname + ' ' + user.lastname;
                this._user.email = user.mail;
                this._userSubject.next(this._user);
                this._retrieving = false;
              }
            );
        }
      );
  }

  public logout(): void {
    this.storageService.remove('accessToken');
    this.storageService.remove('refreshToken');
    this.storageService.remove('orgaID');
    this.storageService.remove('userID');

    this._user = null;
    this._retrieving = false;
  }

  public async getCurrentUser(): Promise<Observable<any>> {
    const userID = await this.storageService.get('userID');

    return this.httpClient.get<User>(environment.apiBaseUrl + `/users/` + userID);
  }

  public getSubscriptionState(): SubscriptionState {
    let subscriptionState = SubscriptionState.NOT_UP_TO_DATE;

    if (
      this._user &&
      parseInt(this._user.operatorsInfo.subscriptionType.toString(), 10) >= 0 &&
      this._user.operatorsInfo.subscriptionDate
    ) {
      if (!this._user.operatorsInfo.valid) {
        return SubscriptionState.UNDER_VALIDATION;
      } else if (moment(this._user.operatorsInfo.subscriptionDate).isBefore(moment())) {
        subscriptionState = SubscriptionState.UP_TO_DATE;
      } else {
        subscriptionState = SubscriptionState.UP_TO_DATE_IN_THE_FUTUR;
      }
    }

    return subscriptionState;
  }
}
