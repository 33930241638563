import { InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx/';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
import { PipesModule } from './shared/pipes/pipes.module';
import { HttpClientHeaders } from './shared/services/http-client-headers.service';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import { AuthInterceptor } from './shared/services/auth.interceptor';
import { SharedService } from './shared/services/shared.service';
import { StorageService } from './shared/services/storage.service';
import { TimeoutInterceptor } from './shared/services/timeout.interceptor';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx';
// import { MaxLengthDirective } from './shared/directives/max-length.directive';

export async function tokenGetter() {
    return await this.StorageService.get('accessToken');
}

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        PipesModule.forRoot(),
        JwtModule.forRoot({
            config: {
                tokenGetter,
                disallowedRoutes: [
                    environment.apiBaseUrl + '/login',
                    environment.apiBaseUrl + '/logout',
                ],
            }
        }),
    ],
    providers: [
        BarcodeScanner,
        StatusBar,
        SplashScreen,
        SharedService,
        HttpClientHeaders,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: InjectableRxStompConfig,
            useValue: environment.stompConfig,
        },
        {
            provide: RxStompService,
            useFactory: rxStompServiceFactory,
            deps: [InjectableRxStompConfig],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
        StorageService,
        InAppBrowser,
        SafariViewController
    ],
  exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
