import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class SharedService {
    private readonly subjectSource$ = new Subject<string>();

    public get newData(): Observable<string> {
        return this.subjectSource$.asObservable();
    }

    public publish(data: any) {
        this.subjectSource$.next(data);
    }
}
