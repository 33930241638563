import * as moment from 'moment';

export function sameDay(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}

export const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');

export const formatDate = (date: Date|string, format: string = null) => {
    if (!format) {
        format = 'YYYY-MM-DD HH:mm:ss';
    }

    return moment(date).format(format);
};

export const version = 'V1.0.6';

