import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
    private defaultTimeout = 15000;

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
        const timeoutValue = Number(httpRequest.headers.get('timeout')) || this.defaultTimeout;
        return next.handle(httpRequest).pipe(timeout(timeoutValue));
    }
}
