import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { StorageService } from './shared/services/storage.service';
import { SafemodeService } from './shared/services/safemode.service';
import { LoginService } from './shared/services/login.service';
import { Router } from '@angular/router';
import { SplashScreen } from '@capacitor/core';
import { CurrentUserService } from './shared/services/current-user.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {

    constructor(private platform: Platform, private statusBar: StatusBar, private storageService: StorageService,
                private safemodeService: SafemodeService, private loginService: LoginService, public router: Router,
                private currentUserService: CurrentUserService) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            SplashScreen.hide();
        });
        this.loginService.isAuthenticated().then(isAuthenticated => {
                if (isAuthenticated) {
                    // Si authentifié, on récupère les secrets au lancement de l'app
                    this.safemodeService.fetchAndStoreSecrets();
                    this.currentUserService.retrieveUser();
                } else if (!this.platform.is('mobileweb') && !this.platform.is('desktop')) {
                    console.error('AppComponent initializeApp loginService.isAuthenticated().then redirect /', isAuthenticated);
                    this.router.navigate(['/']);
                }
            }
        );
    }
}
