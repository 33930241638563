import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { catchError, tap } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { SharedService } from './shared.service';
import { IonIcon, IonInput } from '@ionic/angular';
import { CurrentUserService } from './current-user.service';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor(private httpClient: HttpClient, public jwtHelper: JwtHelperService,
                private storageService: StorageService, private sharedService: SharedService,
                private currentUserService: CurrentUserService) {
    }

    protected getFormData(object: object): string {
        const formData: string[] = [];
        Object.keys(object).forEach(key =>
          formData.push(encodeURIComponent(key) + '=' + encodeURIComponent(object[key]))
        );
        return formData.join('&');
    }

    public async getUser(): Promise<any> {
        return await this.storageService.get('orgaID');
    }

    public login(username: string, password: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        };

        const data = this.getFormData({
            'grant-type': 'password',
            username,
            password,
            client_id: 'MOBILE'
        });

        return this.httpClient.post(environment.apiBaseUrl + '/login', data, httpOptions);
    }

    public logout(refreshToken?: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                Security: 'Bearer ' + refreshToken
            })
        };

        this.sharedService.publish('');
        this.currentUserService.logout();
        return this.httpClient.delete<any>(environment.apiBaseUrl + '/logout', httpOptions);
    }

    public refreshToken(refreshToken: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                Security: 'Bearer ' + refreshToken,
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        };
        const data = 'client_id=MOBILE';

        this.currentUserService.logout();

        return this.httpClient.post<any>(environment.apiBaseUrl + '/token', data, httpOptions)
        .pipe(
            tap(res => {
                console.debug('LoginService refreshToken HttpClient.post', res);

                this.storageService.set('accessToken', res.access_token);
                this.storageService.set('refreshToken', res.refresh_token);
                this.storageService.set('orgaID', res.orgaID);
                this.storageService.set('userID', res.userID);

                this.currentUserService.retrieveUser();
            }),
            catchError((error) => {
                return throwError(error);
            })
        );
    }

    public async isAuthenticated(): Promise<boolean> {
        const getToken = await this.storageService.get('refreshToken');
        if (!getToken) {
            console.error('LoginService isAuthenticated', false);
            return false;
        } else {
            console.error('LoginService isAuthenticated', !this.jwtHelper.isTokenExpired(getToken));
            return !this.jwtHelper.isTokenExpired(getToken);
        }
    }


    public togglePasswordVisibility(passwordField: IonInput, passwordIcon: IonIcon) {
        if (passwordField.type === 'password') {
            passwordField.type = 'text';
            passwordIcon.src = '/assets/eyes.svg';
        } else {
            passwordField.type = 'password';
            passwordIcon.src = '/assets/eyes-open.svg';
        }
    }
}
