import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClientHeaders } from './http-client-headers.service';
import { StorageService } from './storage.service';
import { Observable } from 'rxjs';
import { Secret } from '../model/secret.model';
import { sameDay, zeroPad } from '../utils/functions-utils';
import * as CryptoJS from 'crypto-js';
import CRC32 from 'crc/crc32';
import { Buffer } from 'buffer/';

@Injectable({
    providedIn: 'root'
})
export class SafemodeService {

    private readonly SAFEMODE_URL = environment.apiBaseUrl + '/safemode/';
    private readonly FIXED_SECRET = 'e8a9312ab042cb069e4ce6df82135d53';

    constructor(private httpClient: HttpClientHeaders, private storageService: StorageService) {
    }

    public getSecrets(groupOperatorID: string): Observable<Secret[]> {
        return this.httpClient.get<Secret[]>(this.SAFEMODE_URL + groupOperatorID);
    }

    public fetchAndStoreSecrets(): void {
        Promise.all([this.storageService.get('lastSecretsFetch'), this.storageService.get('secrets')]).then(results => {
            const lastSecretsFetch: Date = new Date(results[ 0 ]);
            const secrets: { groupOperator: string, secrets: Secret[] } = results[ 1 ];

            if (!secrets?.secrets.length || !lastSecretsFetch || !sameDay(lastSecretsFetch, new Date())) {
                this.storeSecrets();
            }
        });
    }

    public storeSecrets(): void {
        this.getSecrets('CLI').subscribe(secrets => {
            if (secrets) {
                this.storageService.set('lastSecretsFetch', new Date());
                this.storageService.set('secrets', { groupOperator: 'CLI', secrets });
            }
        }, error => console.error(error));
    }

    public async getSecretOfTheDay(): Promise<Secret> {
        return await this.storageService.get('secrets').then((secrets: { groupOperator: string, secrets: Secret[] }) => {
            if (!secrets || secrets.secrets?.length === 0) {
                throw new Error('Le code d\'ouverture n\'a pas pu être généré par le mode secours car les données requises ne sont pas renseignées.');
            }
            const secret = secrets.secrets.find(value => sameDay(new Date(value.date), new Date()));
            if (!secret) {
                throw new Error('Le code d\'ouverture n\'a pas pu être généré par le mode secours car les données requises aujourd\'hui ne sont pas renseignées.');
            }
            return secret;
        });
    }

    public generateCode(operatorID: string, manufacturerID: string, systemID: string, secret: string): string {

        // Get UTC time (GMT)
        const now: Date = new Date();
        const today = `${now.getUTCFullYear()}${zeroPad(now.getUTCMonth() + 1, 2)}${zeroPad(now.getUTCDate(), 2)}`;

        // Create the string to be encoded: manufacturerID + systemID + operatorID + current date
        const codeBase = `${manufacturerID}${systemID}${operatorID}${today}`;

        const conversionEncryptOutput = CryptoJS.AES.encrypt(codeBase.trim(), CryptoJS.enc.Hex.parse(secret.trim()),
            { iv: CryptoJS.enc.Hex.parse(this.FIXED_SECRET.trim()) }).toString();

        return CRC32(Buffer.from(conversionEncryptOutput, 'utf8')).toString().substring(0, 6);
    }
}

